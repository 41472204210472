import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';
import { generateCode, getMaintenance } from '../api/auth';

const TYPES = {
  GET_AUTH_CODE: 1,
  GET_AUTH_RULES: 2,
};

const InitialData = types.model('InitialData').props({
  email: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  publicAddress: types.maybeNull(types.string),
});

const LoginMethod = types.model('LoginMethod').props({
  EMAIL: types.boolean,
  GOOGLE: types.boolean,
  DM2: types.boolean,
  IMMUTABLE: types.boolean,
});

const LoginMaintenance = types.model('LoginMaintenance').props({
  DM2: types.boolean,
  EMAIL: types.boolean,
  GMAIL: types.boolean,
  IMMUTABLE: types.boolean,
});


const AuthStore = Model.named('AuthStore')
  .props({
    loggedIn: types.boolean,
    initialData: InitialData,
    isOpenLoginModal: types.boolean,
    isShowLoginModal: types.boolean,
    isOpenLoginImmutableModal: types.boolean,
    isOpenLoginDM2CModal: types.boolean,
    loginMethodsActive: LoginMethod,
    loginMaintenance: LoginMaintenance,
  })
  .actions(self => ({
    toggleOpenModalLogin() {
      self.isOpenLoginModal = !self.isOpenLoginModal;
    },

    toggleShowModalLogin() {
      self.isShowLoginModal = !self.isShowLoginModal;
    },

    toggleOpenImmutableModalLogin() {
      self.isOpenLoginImmutableModal = !self.isOpenLoginImmutableModal;
    },

    toggleOpenDM2CModalLogin() {
      self.isOpenLoginDM2CModal = !self.isOpenLoginDM2CModal;
    },

    setLoggedIn(payload) {
      self.loggedIn = payload;
    },

    setInitialData(data) {
      Object.keys(data).forEach(key => {
        self.initialData[key] = data[key];
      });
    },

    getInitialData() {
      return self.initialData;
    },

    getAuthCode(payload) {
      return self.request({
        type: TYPES.GET_AUTH_CODE,
        api: generateCode,
        payload,
      });
    },

    getRulesLogin() {
      return self.request({
        type: TYPES.GET_AUTH_RULES,
        api: getMaintenance,
        onSuccess: result => {
          const loginMaintenances = result?.login_access?.game_site || {
            EMAIL: false,
            GMAIL: false,
            DM2: false,
            IMMUTABLE: false,
          }
          self.loginMaintenance = loginMaintenances;
        },
      });
    },
  }));

export { TYPES };
export default AuthStore.create({
  isOpenLoginModal: false,
  isShowLoginModal: true,
  isOpenLoginImmutableModal: false,
  isOpenLoginDM2CModal: false,
  loggedIn: false,
  initialData: {
    publicAddress: '',
  },
  loginMethodsActive: {
    EMAIL: false,
    GOOGLE: false,
    DM2: false,
    IMMUTABLE: false,
  },
  loginMaintenance: {
    EMAIL: true,
    GMAIL: true,
    DM2: true,
    IMMUTABLE: true,
  },
});
