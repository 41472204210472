import CryptoJS from 'crypto-js';

// export function encryptData(data, secretKey, iv) {
//   const key = CryptoJS.SHA512(secretKey).toString(CryptoJS.enc.Hex).substring(0, 32);
//   const encryptionIV = CryptoJS.SHA512(iv).toString(CryptoJS.enc.Hex).substring(0, 16);

//   // Convert the key and IV into WordArray (required by crypto-js)
//   const keyWordArray = CryptoJS.enc.Hex.parse(key);
//   const ivWordArray = CryptoJS.enc.Hex.parse(encryptionIV);

//   // Encrypt the data
//   const encrypted = CryptoJS.AES.encrypt(data, keyWordArray, {
//     iv: ivWordArray,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7,
//   });

//   // Return the encrypted data in Base64 format
//   return encrypted.toString();

// }

export function encryptData(data, secretKey, iv) {
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const ivParse = CryptoJS.enc.Utf8.parse(iv);

  return CryptoJS.AES.encrypt(data, key, {
    iv: ivParse,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
}
